<template>
	<div ref="wrapper">
		<div class="row">
			<div v-if="!is_single && showDay" class="col-md-3 col-sm-3 col-xs-3">
				<div class="item">
					<div
						class="item-text"
						:style="`width: ${inner_size}px;height: ${inner_size}px;  font-size: ${number_font_size}px;`"
					>
						<div class="inner-text" :style="`width: ${inner_size}px;height: ${inner_size}px; `">
							<p>{{ factor * days }}</p>
							<span style>Jours</span>
						</div>
					</div>
					<svg :width="inner_size" :height="inner_size" xmlns="http://www.w3.org/2000/svg">
						<circle
							:r="r"
							:cy="cx"
							:cx="cy"
							:stroke-width="strokeWidth"
							:stroke="underneathStrokeColor"
							:fill="minutesFillColor"
						/>
						<circle
							:transform="'rotate(-90, '+cx+', '+cy+')'"
							:style="{strokeDasharray: stroke_dasharray_day, strokeDashoffset: stroke_dashoffset_day}"
							class="circle_animation"
							:r="r"
							:cy="cx"
							:cx="cy"
							:stroke-width="strokeWidth"
							:stroke="secondsStrokeColor"
							fill="transparent"
						/>
					</svg>
				</div>
			</div>
			<div v-if="!is_single && showHour" class="col-md-3 col-sm-3 col-xs-3">
				<div class="item">
					<div
						class="item-text"
						:style="`width: ${inner_size}px; height: ${inner_size}px; font-size: ${number_font_size}px;`"
					>
						<div class="inner-text" :style="`width: ${inner_size}px; height: ${inner_size}px;`">
							<p style>{{ factor * hours }}</p>
							<span style>Heures</span>
						</div>
					</div>
					<svg :width="inner_size" :height="inner_size" xmlns="http://www.w3.org/2000/svg">
						<circle
							:r="r"
							:cy="cx"
							:cx="cy"
							:stroke-width="strokeWidth"
							:stroke="underneathStrokeColor"
							:fill="hoursFillColor"
						/>
						<circle
							:transform="'rotate(-90, '+cx+', '+cy+')'"
							:style="{strokeDasharray: stroke_dasharray_hour, strokeDashoffset: stroke_dashoffset_hour}"
							class="circle_animation"
							:r="r"
							:cy="cx"
							:cx="cy"
							:stroke-width="strokeWidth"
							:stroke="hoursStrokeColor"
							fill="transparent"
						/>
					</svg>
				</div>
			</div>
			<div v-if="!is_single && showMinute" class="col-md-3 col-sm-3 col-xs-3">
				<div class="item">
					<div
						class="item-text"
						:style="`width: ${inner_size}px; height: ${inner_size}px; font-size: ${number_font_size}px;`"
					>
						<div class="inner-text" :style="`width: ${inner_size}px; height: ${inner_size}px;`">
							<p style>{{ factor * minutes }}</p>
							<span style>Minutes</span>
						</div>
					</div>
					<svg :width="inner_size" :height="inner_size" xmlns="http://www.w3.org/2000/svg">
						<circle
							:r="r"
							:cy="cx"
							:cx="cy"
							:stroke-width="strokeWidth"
							:stroke="underneathStrokeColor"
							:fill="minutesFillColor"
						/>
						<circle
							:transform="'rotate(-90, '+cx+', '+cy+')'"
							:style="{strokeDasharray: stroke_dasharray_minute, strokeDashoffset: stroke_dashoffset_minute}"
							class="circle_animation"
							:r="r"
							:cy="cx"
							:cx="cy"
							:stroke-width="strokeWidth"
							:stroke="minutesStrokeColor"
							fill="transparent"
						/>
					</svg>
				</div>
			</div>
			<div v-if="showSecond" class="col-md-3 col-sm-3 col-xs-3">
				<div class="item">
					<div
						class="item-text"
						:style="`width: ${inner_size}px; height: ${inner_size}px; font-size: ${number_font_size}px; `"
					>
						<div class="inner-text" :style="`width: ${inner_size}px; height: ${inner_size}px;`">
							<p style>{{ factor * seconds }}</p>
							<span style>Secondes</span>
						</div>
					</div>
					<svg :width="inner_size" :height="inner_size" xmlns="http://www.w3.org/2000/svg">
						<circle
							:r="r"
							:cy="cx"
							:cx="cy"
							:stroke-width="strokeWidth"
							:stroke="underneathStrokeColor"
							:fill="secondsFillColor"
						/>
						<circle
							:transform="'rotate(-90, '+cx+', '+cy+')'"
							:style="{strokeDasharray: stroke_dasharray_second, strokeDashoffset: stroke_dashoffset_second}"
							class="circle_animation"
							:r="r"
							:cy="cx"
							:cx="cy"
							:stroke-width="strokeWidth"
							:stroke="secondsStrokeColor"
							fill="transparent"
						/>
					</svg>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		initialValue: {
			type: Number,
			default: 0,
			required: true
		},

		strokeWidth: {
			type: Number,
			default: 5
		},

		secondsStrokeColor: {
			type: String,
			default: '#acdb28'
		},

		minutesStrokeColor: {
			type: String,
			default: '#729adb'
		},

		hoursStrokeColor: {
			type: String,
			default: '#db47a0'
		},

		daysStrokeColor: {
			type: String,
			default: '#db47a0'
		},

		underneathStrokeColor: {
			type: String,
			default: '#eee'
		},

		secondsFillColor: {
			type: String,
			default: 'none'
		},

		minutesFillColor: {
			type: String,
			default: 'none'
		},

		hoursFillColor: {
			type: String,
			default: 'none'
		},

		daysFillColor: {
			type: String,
			default: 'none'
		},

		screenSize: {
			type: Number,
			default: 0
		},

		padding: {
			type: Number,
			default: 0
		},

		dayLabel: {
			type: String,
			default: 'day'
		},

		hourLabel: {
			type: String,
			default: 'hours'
		},

		minuteLabel: {
			type: String,
			default: 'minutes'
		},

		secondLabel: {
			type: String,
			default: 'seconds'
		},

		showSecond: {
			type: Boolean,
			default: true
		},

		showMinute: {
			type: Boolean,
			default: true
		},

		showHour: {
			type: Boolean,
			default: true
		},

		showDay: {
			type: Boolean,
			default: true
		},

		steps: {
			type: Boolean,
			default: undefined
		},

		paused: {
			type: Boolean,
			default: false
		},

		notifyEvery: {
			type: String,
			default: 'second',
			validator: val =>
				['second', 'minute', 'hour', 'day', 'none'].includes(val)
		}
	},

	data: function() {
		return {
			isMounted: false,
			value: this.initialValue,
			labelFontRatio: 0.15,
			numberFontRatio: 0.2,
			baseTime: 0,
			size: 0
		}
	},

	computed: {
		second_step: function() {
			return this.is_single
				? this.steps
					? Math.max(this.steps, this.initialValue)
					: this.initialValue
				: 60
		},

		is_single: function() {
			return (
				this.steps !== undefined ||
				(!this.showHour && !this.showMinute && !this.showDay)
			)
		},

		days: function() {
			return Math.floor(Math.abs(this.value) / 86400)
		},

		hours: function() {
			let reminder = this.value % 86400

			return Math.floor(Math.abs(reminder) / 3600)
		},

		minutes: function() {
			let reminder = (this.value % 86400) % 3600

			return Math.floor(Math.abs(reminder) / 60)
		},

		seconds: function() {
			return this.is_single
				? this.value
				: Math.abs(((this.value % 86400) % 3600) % 60)
		},

		factor: function() {
			return this.value >= 0 ? 1 : -1
		},

		circle_length: function() {
			return 2 * Math.PI * this.r
		},

		day_step_length: function() {
			return this.circle_length
		},

		hour_step_length: function() {
			return this.circle_length / 24
		},

		minute_step_length: function() {
			return this.circle_length / 60
		},

		second_step_length: function() {
			return this.circle_length / this.second_step
		},

		stroke_dasharray_day: function() {
			return this.circle_length
		},

		stroke_dasharray_hour: function() {
			return this.circle_length
		},

		stroke_dasharray_minute: function() {
			return this.circle_length
		},

		stroke_dasharray_second: function() {
			return this.circle_length
		},

		stroke_dashoffset_day: function() {
			return this.circle_length - this.days * this.day_step_length
		},

		stroke_dashoffset_hour: function() {
			return this.circle_length - this.hours * this.hour_step_length
		},

		stroke_dashoffset_minute: function() {
			return this.circle_length - this.minutes * this.minute_step_length
		},

		stroke_dashoffset_second: function() {
			return this.circle_length - this.seconds * this.second_step_length
		},

		cx: function() {
			return this.inner_size / 2
		},

		cy: function() {
			return this.inner_size / 2
		},

		r: function() {
			return (this.inner_size - this.strokeWidth) / 2
		},

		inner_size: function() {
			return this.circle_size - this.padding * 2
		},

		circle_size: function() {
			const size =
				this.size === 0 && this.isMounted
					? this.$refs.wrapper.parentElement.clientHeight
					: this.size

			return this.has_label ? size - size * this.labelFontRatio : size
		},

		container_height: function() {
			return this.size === 0 && this.isMounted
				? this.$refs.wrapper.parentElement.clientHeight
				: this.size
		},

		container_width: function() {
			let circles = 0
			if (this.showSecond) {
				circles++
			}

			if (!this.is_single && this.showMinute) {
				circles++
			}
			if (!this.is_single && this.showHour) {
				circles++
			}

			if (!this.is_single && this.showDay) {
				circles++
			}

			return this.inner_size * circles + this.padding * (circles * 2)
		},

		has_label: function() {
			return (
				this.dayLabel != '' ||
				this.hourLabel !== '' ||
				this.minuteLabel !== '' ||
				this.secondLabel !== ''
			)
		},

		label_font_size() {
			return this.circle_size * this.labelFontRatio
		},
		number_font_size() {
			return this.circle_size * this.numberFontRatio
		}
	},

	watch: {
		seconds: function() {
			if (this.notifyEvery === 'second') {
				this.notifyChange()
			}
		},

		minutes: function() {
			if (this.notifyEvery === 'minute' && !this.is_single) {
				this.notifyChange()
			}
		},
		hours: function() {
			if (this.notifyEvery === 'hour' && !this.is_single) {
				this.notifyChange()
			}
		},

		days: function() {
			if (this.notifyEvery === 'day' && !this.is_single) {
				this.notifyChange()
			}
		}
	},

	created: function() {
		if (this.screenSize) {
			this.size = this.screenSize
		} else {
			this.setSize()
		}

		$(window).resize(this.setSize)
	},

	mounted: function() {
		this.$nextTick(() => {
			this.isMounted = true
			if (this.value) {
				const interval = setInterval(
					function() {
						if (this.paused) {
							return
						}

						const delta = 1
						this.value -= delta

						if (this.value === 0) {
							this.$emit('finish')
						}

						if (this.value <= 0 && !this.showNegatives) {
							this.value = 0
							clearInterval(interval)
						}
					}.bind(this),
					1000
				)
			}
		})
	},

	methods: {
		notifyChange: function() {
			let output = { value: this.value }

			if (!this.is_single) {
				output = {
					...output,
					...{
						seconds: this.seconds,
						minutes: this.minutes,
						hours: this.hours,
						days: this.days
					}
				}
			}

			this.$emit('update', output)
		},

		updateTime: function(seconds) {
			if (this.value) this.value += seconds
			if (this.value < 0) {
				this.$emit('finish')
			}
		},

		setSize: function() {
			let width = $(window).innerWidth()
			if (width <= 768) {
				this.size = 118
			} else {
				this.size = 150
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.item {
	float: 'left';
	direction: 'ltr';
	position: 'relative';

	.item-text {
		position: absolute;
		text-align: center;

		.inner-text {
			position: relative;
			display: table-cell;
			vertical-align: middle;
			text-align: center;

			span,
			p {
				color: #ffff01;
				font-family: 'Neue Haas Grotesk Text Pro Roman', sans-serif;
				font-size: 17px;
				font-weight: 700;
				line-height: 25px;
			}

			p {
				font-size: 50px;
			}
		}
	}

	circle {
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
	}
}

@media screen and (max-width: 768px) {
	.item .item-text .inner-text {
		p {
			font-size: 28px;
			line-height: 10px;
		}

		span {
			font-size: 12px;
		}
	}
}
</style>