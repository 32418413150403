/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

require('jquery-smooth-scroll')
require('magnific-popup')
require('jquery.easing')

Vue.component('counter-component', require('./components/website/Counter.vue').default)

if ($('#app').length > 0) {
    const app = new Vue({
        el: '#app'
    })
}